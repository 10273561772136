import React , {useState} from 'react';


// react mosanry------------------------
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


// react paginate-----------------------
import ReactPaginate from "react-paginate"

// components-------------------------
import AvatarFrame from './AvatarFrame';


//styles------------------------- 
import styles from "../styles/body.module.scss";

// data----------------------------------
import {normal , long, crop} from "../data/data.js"
// import { styled } from 'styled-components';





const Body = () => {

  // type of Tshirt-------------------------
  const [type , setType] =useState(normal||[]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage=32;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = type.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(type.length / itemsPerPage);
  const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % type.length;
    setItemOffset(newOffset);
  };


  const [name , setName]=useState("normal")
  const changeHandler=(ev)=>{
    
    const target =ev.target.value;
    if(target==="long"){
      setType(long)
      setName("long")
    }else if(target==="crop"){
      setType(crop)
      setName("crop")
    }else if(target==="normal") {
      setType(normal)
      setName("normal")
     
    }
  }
    return (
        <div  className={`col-12 d-flex justify-content-center  ${styles.BodyParent}`}>
            <div className={`col-11 ${styles.innerParent}`}> 
            <div className={`d-flex flex-wrap my-3 col-12 ${styles.kindParent}`}>
                      <form className='d-flex '>
                        <span className={`col-3 ${styles.chooseTShirt}`}>انتخاب نوع تیشرت :</span>
                        <select onChange={changeHandler}  value={name} className={`col-3 ${styles.selectParent}`}>
                            <option  value="normal">نرمال</option>
                            <option  value="crop">کراپ</option>
                            <option  value="long"> لانگ </option>
                         </select>
                      </form>
             </div>   
                  {/* masonry------------------------------------------------------ */}
                  <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 4}}
                        className={styles.masonry}
                        
                      >
                      <Masonry className={styles.test} gutter='10px'>           
                          {
                            currentItems.map(item =>{
                              return <AvatarFrame dataName={name} key={item.id} val={item} />
                            })
                          }
                      </Masonry>
                </ResponsiveMasonry>

                  
             </div>
             {/* <Paginate mood={mood} style={{color:mood ? "black" :"white"}} className={`d-flex justify-content-center ${styles.paginateParent}`}>
              <ReactPaginate
                className={` ${styles.paginate}`}
                breakLabel="..."
                nextLabel="بعدی >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="< قبلی"
                renderOnZeroPageCount={null}
                />
             </Paginate> */}
         </div>
    );
};

export default Body; 