import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// styles--------------------------------------------
import styles from "../styles/avatarFrame.module.scss"
// bootstrap icons ----------------------------------
import {HeartFill } from "react-bootstrap-icons";






const AvatarFrame = ({val ,dataName}) => {
    const [heart , setHeart] =useState(false)
    
    try{
        return (
            <div className={`${styles.avatarFrame}`}>
                 <Link to={`/tshirt/${dataName}/${val.personName}`}>
                    <div className={styles.imgParent}>
                        <img src={require(`../images/${val.image}.png`)}  alt={val.id} />
                    </div>
                </Link>
                <HeartFill onClick={()=>setHeart(!heart)} style={{color : heart ? "red" : "white" ,position:"absolute" ,top:"10px" , right:"15px"}} size={25} className={styles.heart} />
            </div>
        );
    }
    catch{
    
    }
};

export default AvatarFrame;