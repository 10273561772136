
const normal =[
    {
        id:1 ,
        image:"normal/00641_00",
        personName:"00641_00"
    },
    {
        id:2 ,
        image:"normal/00739_00",
        personName:"00739_00"
    },
    {
        id:3 ,
        image:"normal/00992_00",
        personName:"00992_00"
    },
    {
        id:4 ,
        image:"normal/01645_00",
        personName:"01645_00"
    },
    {
        id:5 ,
        image:"normal/02082_00",
        personName:"02082_00"
    },
    {
        id:6 ,
        image:"normal/02500_00",
        personName:"02500_00"
    },
    {
        id:7 ,
        image:"normal/02948_00",
        personName:"02948_00"
    },
    {
        id:8 ,
        image:"normal/02991_00",
        personName:"02991_00"
    },
    {
        id:9 ,
        image:"normal/03838_00",
        personName:"03838_00"
    },
    {
        id:10 ,
        image:"normal/04518_00",
        personName:"04518_00"
    },
    {
        id:11 ,
        image:"normal/05184_00",
        personName:"05184_00"
    },
    {
        id:12 ,
        image:"normal/05242_00",
        personName:"05242_00"
    },
    {
        id:13 ,
        image:"normal/05852_00",
        personName:"05852_00"
    },
    {
        id:14 ,
        image:"normal/06647_00",
        personName:"06647_00"
    },
    {
        id:15 ,
        image:"normal/08340_00",
        personName:"08340_00"
    },
    {
        id:16 ,
        image:"normal/08673_00",
        personName:"08673_00"
    },
    {
        id:17 ,
        image:"normal/08700_00",
        personName:"08700_00"
    },
    {
        id:18 ,
        image:"normal/13260_00",
        personName:"13260_00"
    },
    {
        id:19 ,
        image:"normal/09757_00",
        personName:"09757_00"
    },
    {
        id:20 ,
        image:"normal/09963_00",
        personName:"09963_00"
    },
    {
        id:21 ,
        image:"normal/10352_00",
        personName:"10352_00"
    },
    {
        id:22 ,
        image:"normal/12562_00",
        personName:"12562_00"
    },
    {
        id:23 ,
        image:"normal/11902_00",
        personName:"11902_00"
    },
    {
        id:24 ,
        image:"normal/11903_00",
        personName:"11903_00"
    },
    {
        id:25 ,
        image:"normal/12030_00",
        personName:"12030_00"
    },
    {
        id:26 ,
        image:"normal/12044_00",
        personName:"12044_00"
    },
    {
        id:27 ,
        image:"normal/12215_00",
        personName:"12215_00"
    },
    {
        id:28 ,
        image:"normal/12345_00",
        personName:"12345_00"
    },
    {
        id:29 ,
        image:"normal/12485_00",
        personName:"12485_00"
    },    
]
const long =[ 
    {
        id:1 ,
        image:"long/01154_00",
        personName:"01154_00"
    },
   
    {
        id:2 ,
        image:"long/09509_00",
        personName:"09509_00"
    },
    {
        id:3 ,
        image:"long/11638_00",
        personName:"11638_00"
    },
    {
        id:4 ,
        image:"long/14248_00",
        personName:"14248_00"
    },
]
 
const crop=[
    {
        id:1 ,
        image:"crop/00190_00",
        personName:"00190_00"
    },
    {
        id:2 ,
        image:"crop/01815_00",
        personName:"01815_00"
    },
    {
        id:3 ,
        image:"crop/04836_00",
        personName:"04836_00"
    },
    {
        id:4 ,
        image:"crop/10927_00",
        personName:"10927_00"
    },
]

export{
    normal , 
    long, 
    crop
}