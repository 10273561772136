import React , {useEffect, useState}  from 'react';
import { useParams , Link } from 'react-router-dom';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner'


// color Picker------------------------------
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";


// bootstrapIcons
import { App, ArrowDownCircle, ArrowUpCircle, Star} from 'react-bootstrap-icons';

// styles------------------------------------
import styles from "../styles/choosenAvatar.module.scss";


const ChoosenAvatar = () => {
    const [color, setColor] = useColor("#561ecb");
    const [image , setImage] =useState();
    const params =useParams();
    const [resImg , setResImg] =useState('');
    const [loading, setLoading] = useState();
    const [API , setAPI] =useState();
  
    // error handler------------------------------
    const [error, setError] = useState(null);


    
    useEffect(()=>{
        setImage(()=>`${params.type}/${params.id}`);
    }
    ,[])
    // form ------------------------------------------
    const [txt , setTxt] =useState('');
    const [fileN, setfileN ]= useState();
    
    
    const changeHandler=async(e)=>{
    
       const dataM =new FileReader();
        dataM.addEventListener('load',()=>{
            setTxt(dataM.result);
                })
        const file = e.target.files[0]; 
         dataM.readAsDataURL(file)
            setfileN(file.name);
        }


    const formHandler= async()=>{
        setLoading(true);
        setError("")
            const newTxt=txt.replace(/^data:image\/[a-z]+;base64,/,"");
            console.log(txt);
            let data = JSON.stringify({
                    "person_name": params.id ,
                    "base64_image":newTxt ,
                    "tshirt_type": params.type
                });
                
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://xstudioo.ir/api/',
                headers: { 
                'Content-Type': 'application/json'
                },
                data 
            };
          
          
            axios.request(config)
            .then(
                (response) => {
                    let newRes =JSON.stringify(response.data).replace('"output_img":', '');
                    let finalNew =newRes.replace(/"/g, '' );
                    let cleanedResImg = finalNew.replace(/^\{(.*)\}$/, '$1')
                    setLoading(false)
                return setResImg(cleanedResImg);
        
            })
            .catch((error) => {
                console.log(error);
                setError("خطایی رخ داده است");
                setLoading(false);
            });
          
        }

         
    
    return (
        <div className={`${styles.chooseParent}`}>
            <div className={`col-12 d-flex justify-content-center ${styles.container}`}>
                <div className={`col-11  d-flex flex-wrap justify-content-between  ${styles.imgParent}`}> 
                {/* avatar picture---------------------------------------------------------- */}
                <div className={`col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12 d-flex align-items-start justify-content-between position-relative ${styles.imgContainer}`} >
                    <div className={`col-lg-3  col-sm-4 col-12 d-flex  ${styles.sideImage}`}>
                        <div><Star className={styles.BTS} /><span>علاقه مندی</span></div>
                        <div><ArrowUpCircle  className={styles.BTS} /><span>اشتراک</span></div>
                        <div><ArrowDownCircle  className={styles.BTS} /><span>ذخیره</span></div>
                    </div>
                    
                   {error?error && (
                        <div className={styles.error}>
                            <p className={styles.showErr}>{error}</p>
                        </div>
                    ):loading ? (
                        <InfinitySpin 
                        width='300'
                        color="rgb(9,125,108) "
                      />
                      ) : (
                         resImg ? <img src={`data:image/png;base64,${resImg}`} alt="Image" className={`col-12 col-sm-8 ${styles.avatarImg}`}  /> : (
                         image && <img src={require(`../images/${image}.png`)} alt="choosen avatar" className={`col-12 col-sm-8 ${styles.avatarImg}`} />
                     )
                 )} 
                     

                </div>
            

                <div className={`col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12 d-flex justify-content-end ${styles.middleParent}`}>
                    <div className={`col-12 col-sm-9 ${styles.showAPIParent}`}>
                        <img src={"test"} className={styles.show} alt="showAPI" />
                    </div>
                </div>


                {/* upload image for try--------------------------------------------------------------------- */}
                <div className={`col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12 d-flex flex-column align-items-end  ${styles.formParent}`}>
                    <form  className={`col-12  d-flex flex-column align-items-end  justify-content-between ${styles.form}`}>
                        <input id="files" type='file' name="file" onChange={changeHandler} className={`col-12 col-sm-8 ${styles.customFileInput}`} />
                      
                         <div className={` col-12 col-sm-8 d-flex flex-column align-items-center flex-wrap justify-content-center`}> 
                            <Link onClick={()=>formHandler()} style={{display: txt ? "block" : "none" ,outline:"none"}} className={`col-12 ${styles.btn} `}>
                                امتحانش کن
                            </Link>
                        </div> 
                        <span className={`col-12 col-sm-8  ${styles.fileName}`}>{fileN}</span>
                    </form>
                    {/* color parent------------------------------------------------------------ */}
                    <div className={`col-12 col-sm-8 ${styles.colorParent}`} >
                        <ColorPicker height={115} color={color} onChange={setColor} />
                    </div>

                  </div>
                </div>
            </div>
            
        </div>
    );
};
<script src="base64.js"></script>
export default ChoosenAvatar;