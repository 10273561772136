import React from 'react';
import notFound from "../images/404.png"
import styles from "../styles/notFound.module.scss";

const NotFound = () => {
    return (
          <div className={`d-flex flex-column justify-content-center align-items-center ${styles.notFoundParent}`}>
            <div className={`d-flex flex-column align-items-center ${styles.notInner}`}>
                <img src={notFound} alt='notFound' />
                <small>404</small><span >  Not Found  </span>
            </div>
          </div>
    );
};

export default NotFound;