import React from 'react';

// react helmet------------------------------
import {Helmet} from "react-helmet";
import logo from "./images/logo.png"

// components---------------------------------
import MyNavbar from './components/Navbar';
import Body from './components/Body';
import NotFound from "./components/NotFound"


// bootstrap css and icons------------------------------
import 'bootstrap/dist/css/bootstrap.css';

// main css-----------------------------------
import "./styles/main.css"
import { Routes ,Route} from 'react-router-dom';
import ChoosenAvatar from './components/ChoosenAvatar';

// styles-----------------------------------
import Footer from './components/Footer';




const App = () => {

  return (
    <>
        <Helmet>
           <meta charSet="utf-8" />
           <title>VTon</title>
           <link rel="icon" type="image/png" href={logo} />
        </Helmet> 

        <main className='outerParent d-flex flex-column align-items-center' dir='rtl'>       
          <MyNavbar />
          <Routes>
            <Route path='/tshirt'  element={<Body />} />
            <Route path='/tshirt/:type/:id' element={<ChoosenAvatar />} />
            <Route path='*' element={<NotFound />}/>
          </Routes>
          <Footer />
        </main>
    </>
  );
};

export default App;