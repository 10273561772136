import React ,{useState} from 'react';

// logo----------------------------------
import logo from "../images/logo.png";

// styles--------------------------------
import styles from "../styles/navbar.module.scss";



// bootstarp navbar
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const MyNavbar = () => {
    return (
        <Navbar expand="lg" className={`bg-transparent  ${styles.navParent}`}>
            <Container className={`col-11 ${styles.navContainer}`} fluid>
            <Navbar.Brand href="#" className={`col-3 col-sm-2 col-md-2 col-lg-1 ${styles.logo}`}><img className='col-12' src={logo} alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll"  style={{backgroundColor:'rgb(9,125,108)' }}/>
            <Navbar.Collapse id="navbarScroll">
                <Nav
                className={` d-flex align-items-center  ${styles.aboutUs}`}
                style={{ maxHeight: '100px' }}
                navbarScroll
                >
                <Nav.Link href="/tshirt" className={styles.linkAdd}>پرو تیشرت </Nav.Link>
                <Nav.Link href="#action2" className={styles.linkAdd}>پرو عینک </Nav.Link>
                <Nav.Link href="#action3" className={styles.linkAdd}>پرو ساعت </Nav.Link>
                <Nav.Link href="#action4" className={styles.linkAdd}>تماس با ما </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MyNavbar;

