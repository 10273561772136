import React from 'react';
import styles from "../styles/footer.module.scss";
import {GeoAlt, Link45deg, QuestionCircle, Telephone} from "react-bootstrap-icons"
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={`col-11 d-flex flex-column justify-content-center ${styles.footerParent}`}>
            <div className={`col-12  d-flex flex-wrap justify-content-around ${styles.row}`}>
                <div className='d-flex justify-content-around align-items-center  col-md-3 col-6'><QuestionCircle className={styles.bootIcons} size={70}/> <div className='d-felx felx-column flex-wrap col-8 '><span className={`col-12 ${styles.footerText}`}>درباره ما</span><small className={styles.footerDescribe}>ما یک شرکت خلاق در زمینه....</small></div></div>
                <div className='d-flex justify-content-between align-items-center col-md-3 col-6'><GeoAlt className={styles.bootIcons} size={70}/> <div className='d-felx felx-column flex-wrap col-8 '><span className={`col-12 ${styles.footerText}`}>آدرس</span><small className={styles.footerDescribe}>میدان شیخ بهایی.......</small></div></div>
            </div>
            <div className={`col-12  d-flex flex-wrap justify-content-around ${styles.row}`}>
                <div className='d-flex justify-content-around align-items-center col-md-3 col-6 '><Telephone className={styles.bootIcons} size={70}/> <div className='d-felx felx-column flex-wrap col-8 '><span className={`col-12  ${styles.footerText}`}>تماس با ما</span><small className={styles.footerDescribe}>021-85692529</small></div></div>
                <div className='d-flex justify-content-between align-items-center col-md-3 col-6'><Link45deg className={styles.bootIcons} size={70}/> <div className='d-felx felx-column flex-wrap col-8 '><span className={`col-12 ${styles.footerText}`}>لینک های مفید</span><Link to={"./#"} className={styles.footerDescribe}>Home</Link><Link to="./#">About</Link></div></div>
            </div>
            <div className={`ool-9 ${styles.copyRight}`}>
                Copy right V-sellTech All right reserved
            </div>
        </div>
    );
};

export default Footer;